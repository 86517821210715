@media (max-width: 1500px) {

    main .features-block:after,
    footer:before {
        height: 150px;
    }

    main .features-block:after {
        bottom: -150px;
    }

    footer:before {
        top: -150px;
    }

    .footer-out {
        padding-bottom: 150px;
    }

    main .training-center-block {
        padding-top: 150px;
    }

    main .trainging-inner {
        padding-bottom: 30px;
    }
}

@media (max-width: 1400px) {

    footer .container {
        padding-top: 20px;
    }

}

@media (max-width: 1230px) {
    .header-main .container:before {
        height: 680px;
        width: 500px;
    }

    main .features-list ul {
        width: 430px;
    }
}

@media (max-width: 1200px) {

    * {
        box-sizing: border-box;
    }

    .container:not(.static-content):not(.footer-out) {
        padding-left: 15px;
        padding-right: 15px;
    }

    main .features-block:after,
    footer:before {
        height: 120px;
    }

    main .features-block:after {
        bottom: -120px;
    }

    footer:before {
        top: -120px;
    }

    .footer-out {
        padding-bottom: 120px;
    }

    main .training-center-block {
        padding-top: 120px;
    }

    main .block-out {
        padding-left: 100px;
        padding-right: 100px;
    }

    main .features-list ul li {
        max-width: inherit;
    }

    main .trainging-inner {
        -webkit-column-gap: 70px;
        -moz-column-gap: 70px;
        column-gap: 70px;
    }


    main .features-list ul {
        margin-right: 100px;
        width: 420px;
    }

}

@media (max-width: 1160px) {
    main .features-list ul {
        margin-right: 100px;
    }
}

@media (max-width: 1100px) {
    footer .about-links li {
        margin-right: 35px;
    }

    footer .main-menu li {
        margin-right: 30px;
    }

    footer .social-list li:first-child {
        margin-right: 15px;
    }

    header .inner-block {
        max-width: 480px;
    }
    main .features-list ul {
        width: 400px;
    }

}

@media (max-width: 1050px) {

    main .features-list ul {
        margin-right: 80px;
        width: 380px;
    }

}

@media (max-width: 1000px) {


    main .features-list ul {
        margin-right: 50px;
    }

    .header-main .container:before {
        height: 560px;
        width: 420px;
    }

    footer {
        height: auto;
        padding-bottom: 20px;
    }

    footer .left-block,
    footer .right-block {
        float: none;
        text-align: center;
    }

    footer .footer-logo {
        display: inline-block;
        margin-bottom: 10px;
    }

    footer .about-links {
        margin-bottom: 30px;
    }

    footer .main-menu li,
    footer .about-links li {
        display: block;
        margin-right: 0;
        margin-bottom: 15px;
    }

    footer .copyright-txt {
        display: block;
    }

    footer .left-block {
        margin-bottom: 30px;
    }

    footer .right-block {
        margin-top: 0;
    }

    .wrapper:after {
        display: none;
    }

    html, body {
        height: auto;
    }

    .wrapper {
        min-height: inherit;
        margin-bottom: 0;
    }

    main .features-block:after,
    footer:before {
        height: 105px;
    }

    main .features-block:after {
        bottom: -105px;
    }

    footer:before {
        top: -105px;
    }

    .footer-out {
        padding-bottom: 105px;
    }

    main .training-center-block {
        padding-top: 105px;
    }

    main .password-reset-block {
        margin-top: 100px;
    }

    .fs50,
    .static-content h2 {
        font-size: 2em;
    }

    .fs30 {
        font-size: 1.4em;
    }

    .static-content h3 {
        font-size: 1.667em;
    }

    .static-content h4 {
        font-size: 1.444em;
    }

    .static-content h5 {
        font-size: 1.222em;
    }

    .static-content h6 {
        font-size: 1.111em;
    }

    main .block-out {
        padding-left: 130px;
        padding-right: 130px;
    }

    main .block-out .inner:before {
        top: 9px;
    }

    main .block-out {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    main .block-out .error-block .inner {
        padding-left: 0;
        padding-top: 180px;
    }

    main .block-out .error-block .inner:before {
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    main .block-out .error-block {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 930px) {
    header .inner-block {
        max-width: 430px;
    }


    main .features-list ul {
        width: 360px;
    }
}

@media (max-width: 880px) {

    header .inner-block {
        max-width: 430px;
    }

    main .features-list ul {
        width: 340px;
    }

    header .appStore-googlePlay-lists li:first-child {
        margin-right: 15px;
    }
}


@media (max-width: 860px) {

    header .appStore-googlePlay-lists {
        padding-bottom: 0;
    }

    header .inner-block {
        max-width: inherit;
        padding-top: 30px;
    }

    header.header-main .container {
        padding-bottom: 430px;
    }

    .header-main .container:before {
        height: 430px;
        left: 0;
        margin: 0 auto;
        width: 350px;
    }

    main .features-list ul {
        margin-right: 80px;
        width: 300px;
    }
}

@media (max-width: 830px) {

    main .features-list ul {
        margin-right: 70px;
        width: 290px;
    }

}

@media (max-width: 767px) {

    header .inner-block .txt:last-of-type {
        margin-bottom: 0;
    }

    header.header-inner .main-logo {
        margin-bottom: 0;
    }

    /*header.header-inner,
    header .appStore-googlePlay-lists-small,
    header .appStore-googlePlay-lists,
    footer .right-block {
        display: none;
    }*/

    main .features-list ul {
        float: none;
        margin-right: 0;
        max-width: inherit;
        width: 100%;
    }

    main .training-center-block .container {
        background-size: contain;
        background-position: center;
    }

    main .features-list ul > li .counter-txt {
        font-size: 3.2em;
        top: -12px;
    }

    main .features-list ul li {
        padding-left: 75px;
    }

    header .appStore-googlePlay-lists {
        padding-top: 20px;
    }

    header .appStore-googlePlay-lists .appStore-icon a {
        background-position: left -216px;
    }

    header .appStore-googlePlay-lists li {
        height: 36px;
        width: 124px;
    }

    header .appStore-googlePlay-lists .googlePlay-icon a {
        background-position: -126px -216px;
    }

    header.header-main .main-logo {
        height: 50px;
        margin-bottom: 25px;
        width: 174px;
    }

    header.header-main .main-logo a {
        background-position: -178px -101px;
    }

    main .trainging-inner {
        column-count: 1;
        -moz-column-count: 1;
        -webkit-column-count: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
    }

    main .trainging-inner p {
        max-width: inherit;
    }

    main .block-out .inner:before {
        height: 50px;
        width: 50px;
    }

    main .block-out .inner:before {
        background-position: -53px -420px;
    }

    main .block-out .failed-block .inner:before {
        background-position: left -420px;
    }

    main .block-out .inner {
        padding-left: 75px;
    }

    .static-content {
        padding: 20px 15px 80px;
    }

    main .features-block:after,
    footer:before {
        height: 80px;
    }

    main .features-block:after {
        bottom: -80px;
    }

    footer:before {
        top: -80px;
    }

    .footer-out {
        padding-bottom: 80px;
    }

    main .training-center-block {
        padding-top: 80px;
    }

    main .password-reset-block {
        margin-top: 60px;
    }

    main .block-out {
        padding-left: 70px;
        padding-right: 70px;
    }

    main .block-out .inner-block {
        min-height: 300px;
    }

}

@media (max-width: 580px) {

    main .features-block:after,
    footer:before {
        height: 60px;
    }

    main .features-block:after {
        bottom: -60px;
    }

    footer:before {
        top: -60px;
    }

    .footer-out {
        padding-bottom: 60px;
    }

    main .training-center-block {
        padding-top: 60px;
    }

}

@media (max-width: 550px) {

    main .training-center-block .learn-more-btn {
        width: 100%;
    }

    header.header-inner {
        text-align: center;
    }

    header.header-inner .main-logo,
    header .appStore-googlePlay-lists-small {
        float: none;
    }

    header .appStore-googlePlay-lists-small li:first-child {
        margin-right: 10px;
    }

    header .appStore-googlePlay-lists-small li {
        display: inline-block;
        float: none;
    }

    main .password-reset-block {
        max-width: inherit;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    main .password-reset-block .reset-btn {
        min-width: inherit;
        width: 100%;
    }

}

@media (max-width: 480px) {

    main .features-block:after,
    footer:before {
        height: 50px;
    }

    main .features-block:after {
        bottom: -50px;
    }

    footer:before {
        top: -50px;
    }

    .footer-out {
        padding-bottom: 50px;
    }

    main .training-center-block {
        padding-top: 50px;
    }

    main .block-out {
        padding-left: 30px;
        padding-right: 30px;
    }

    .header-main .container:before {
        height: 385px;
        width: 300px;
    }

    header.header-main .container {
        padding-bottom: 380px;
    }

}

@media (max-width: 380px) {

    main .features-block:after,
    footer:before {
        height: 40px;
    }

    main .features-block:after {
        bottom: -40px;
    }

    footer:before {
        top: -40px;
    }

    .footer-out {
        padding-bottom: 40px;
    }

    main .training-center-block {
        padding-top: 40px;
    }

    main .block-out {
        padding-left: 15px;
        padding-right: 15px;
    }

    main .block-out .error-block .inner:before {
        background-position: -5px -487px;
        height: 110px;
        width: 220px;
    }

    main .block-out .error-block .inner {
        padding-top: 140px;
    }

}
