html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,
a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,
small,strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,canvas,details,embed,
figure,figcaption,footer,header,hgroup,
menu,nav,output,ruby,section,summary,
time,mark,audio,video {
    border: 0;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,main {
    display: block;
}

blockquote,q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html, body {
    height: 100%;
}

body {
    background-color: #FFFFFF;
    font-size: 112.5%;
    line-height: normal;
}

body.lock {
    overflow: hidden;
}

input, textarea {
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
}

textarea {
    resize: none;
}

img {
    border: none;
    max-width: 100%;
    vertical-align: middle;
}

ul, ol {
    list-style: none;
}

:focus {
    outline: 0 !important;
}

::-ms-clear {
    display: none;
}

main .btn[disabled],
main .btn.disabled,
[disabled], .disabled,
input[type="submit"][disabled="disabled"] {
    background-color: #ccc !important;
    cursor: default !important;
}

main .btn.loading-state:active,
main .btn.loading-state,
.loading-state {
    background: url("/img/loader.gif") no-repeat center #ccc !important;
    color: transparent !important;
    cursor: default !important;
}

a {
    color: #1767b3;
    cursor: pointer;
    outline: 0;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

a:focus,
a:active,
a:visited {
    text-decoration: none;
}

i,
em,
.italic {
    font-style: italic;
}

.cb  { clear: both; }
.dn  { display: none; }
.db  { display: block; }
.dib { display: inline-block; }
.fl  { float: left; }
.fr  { float: right; }
.pr  { position: relative; }
.pa  { position: absolute }
.mln { margin-left: 0 !important; }
.tc  { text-align: center; }
.tr  {  text-align: right; }
.tj  { text-align: justify; }
.tu  { text-transform: uppercase; }
.ver-top-box {
    display: inline-block;
    vertical-align: top;
}

.center {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
}

.arm { font-family: 'Arian AMU', 'Arial AMU', 'GHEA Grapalat', 'Sylfaen', 'sans-serif' !important; }
.arl { font-family: 'Helvetica', 'Arial', 'sans-serif' !important; }

.list-inline li {
    float: left;
}

.list-inline:after,
.clear-fix:after {
    content: '';
    clear: both;
    display: block;
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.table {
    display: table;
    height: 100%;
    width: 100%;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

.table-responsive {
    margin-bottom: 20px;
    overflow-x: auto;
    padding-bottom: 10px;
}

.scroller-block {
    overflow: auto;
}

.table-responsive,
.scroller-block {
    -webkit-overflow-scrolling: touch;
    scrollbar-base-color: #1767B3;
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #1767B3;
    scrollbar-track-color: #E9E9E9;
    scrollbar-arrow-color: #1767B3;
    scrollbar-shadow-color: #1767B3;
    scrollbar-dark-shadow-color: #1767B3;
}

.table-responsive::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar,
.scroller-block::-webkit-scrollbar-thumb,
.scroller-block::-webkit-scrollbar-track,
.scroller-block::-webkit-scrollbar {
    border-radius: 3px;
    height: 6px;
    width: 6px;
}

.table-responsive::-webkit-scrollbar-track,
.scroller-block::-webkit-scrollbar-track {
    background-color: #E9E9E9;
}

.table-responsive::-webkit-scrollbar-thumb,
.scroller-block::-webkit-scrollbar-thumb {
    background-color: #1767B3;
}

.trans-color {
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.trans-background {
    -webkit-transition: background 0.3s ease;
    -moz-transition: background 0.3s ease;
    -ms-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    transition: background 0.3s ease;
}

.trans-backgroundColor {
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -ms-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
}

.trans-all{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.trans-transform {
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
}

.trans-opacity {
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 9999s;
	-webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

/* Default font-size 16px */
.fs12 { font-size: 0.667em; }
.fs13 { font-size: 0.722em; }
.fs14 { font-size: 0.778em; }
.fs15 { font-size: 0.833em; }
.fs16 { font-size: 0.889em; }
.fs17 { font-size: 0.944em; }
.fs18 { font-size: 1.000em; }
.fs19 { font-size: 1.056em; }
.fs20 { font-size: 1.111em; }
.fs21 { font-size: 1.167em; }
.fs22 { font-size: 1.222em; }
.fs23 { font-size: 1.278em; }
.fs24 { font-size: 1.333em; }
.fs25 { font-size: 1.389em; }
.fs26 { font-size: 1.444em; }
.fs27 { font-size: 1.500em; }
.fs28 { font-size: 1.556em; }
.fs29 { font-size: 1.611em; }
.fs30 { font-size: 1.667em; }
.fs31 { font-size: 1.722em; }
.fs32 { font-size: 1.778em; }
.fs33 { font-size: 1.833em; }
.fs34 { font-size: 1.889em; }
.fs35 { font-size: 1.944em; }
.fs36 { font-size: 2.000em; }
.fs37 { font-size: 2.056em; }
.fs38 { font-size: 2.111em; }
.fs39 { font-size: 2.167em; }
.fs40 { font-size: 2.222em; }
.fs41 { font-size: 2.278em; }
.fs42 { font-size: 2.333em; }
.fs43 { font-size: 2.389em; }
.fs44 { font-size: 2.444em; }
.fs45 { font-size: 2.500em; }
.fs46 { font-size: 2.556em; }
.fs47 { font-size: 2.611em; }
.fs48 { font-size: 2.667em; }
.fs49 { font-size: 2.722em; }
.fs50 { font-size: 2.778em; }

/* For 1x devices */

.static-content ul > li:before,
.sprite,
.sprite-b:before {
    background-image: url('/img/sprite.png?m=1');
    background-repeat: no-repeat;
}

.sprite-b:before {
    content: '';
}

/* For 2x devices */

@media only screen and ( -webkit-min-device-pixel-ratio: 2 ),
       only screen and ( -webkit-min-device-pixel-ratio: 1.5 ),
       only screen and ( -moz-min-device-pixel-ratio: 2),
       only screen and ( -o-min-device-pixel-ratio: 3/2),
       only screen and ( -o-min-device-pixel-ratio: 2/1),
       only screen and ( min--moz-device-pixel-ratio: 1.5),
       only screen and ( min-device-pixel-ratio: 1.5),
       only screen and ( min-device-pixel-ratio: 2) {

    .static-content ul > li:before,
    .sprite,
    .sprite-b:before {
        background-image: url('/img/sprite2x.png?m=1');
        background-size: 500px auto;
    }

}

/* For 3x devices */

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 480dpi) {

    .static-content ul > li:before,
    .sprite,
    .sprite-b:before {
        background-image: url('/img/sprite3x.png?m=1');
        background-size: 500px auto;
    }

}

/* For 4x devices */

@media (-webkit-min-device-pixel-ratio: 4), (min-resolution: 640dpi) {

    .static-content ul > li:before,
    .sprite,
    .sprite-b:before {
        background-image: url('/img/sprite4x.png?m=1');
        background-size: 500px auto;
    }

}