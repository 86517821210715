@media (min-width: 768px) {
    header .appStore-googlePlay-lists-small a:hover,
    header .appStore-googlePlay-lists a:hover {
        opacity: .75;
    }

    main .btn:hover {
        background-color: #1AD4E2;
    }

    footer .social-list a.fb-icon:hover {
        background-color: #3b5998;
    }

    footer .social-list a.twitter-icon:hover {
        background-color: #00aced;
    }

    footer .about-links li a:hover:after {
        width: 0;
    }

    .static-content a:hover {
        text-decoration: underline;
    }

    footer .main-menu a:hover:before {
        width: 100%;
    }
}

