html, body {
    height: 100%;
}

.container {
    max-width: 1170px;
    margin: 0 auto;
}

header.header-inner {
    padding: 11px 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ec5d1+0,8bebd8+100 */
    background: #1ec5d1; /* Old browsers */
    background: -moz-linear-gradient(top, #1ec5d1 0%, #8bebd8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #1ec5d1 0%,#8bebd8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1ec5d1 0%,#8bebd8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ec5d1', endColorstr='#8bebd8',GradientType=0 ); /* IE6-9 */
}

header.header-main {
    padding-bottom: 230px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ec5d1+0,8bebd8+100 */
    background: #1ec5d1; /* Old browsers */
    background: -moz-linear-gradient(left, #1ec5d1 0%, #8bebd8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #1ec5d1 0%,#8bebd8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #1ec5d1 0%,#8bebd8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ec5d1', endColorstr='#8bebd8',GradientType=1 ); /* IE6-9 */
}

header.header-main .main-logo {
    height: 98px;
    width: 338px;
}

header.header-inner .main-logo {
    height: 50px;
    width: 174px;
}

header.header-inner .main-logo a {
    background-position: -178px -101px;
}

header .main-logo {
    display: inline-block;
}

header .appStore-googlePlay-lists-small a,
footer .footer-logo a,
header .appStore-googlePlay-lists a,
header .main-logo a {
    display: inline-block;
    height: 100%;
    text-indent: -99999px;
    width: 100%;
}

header .inner-block {
    color: #fff;
    max-width: 540px;
    padding-top: 90px;
    z-index: 1;
}

header .inner-block .main-logo {
    margin-bottom: 37px;
}

header .inner-block .static-content {
    padding-bottom: 40px;
}

header .inner-block .title {
    margin-bottom: 13px;
}

.static-content p,
.txt {
    line-height: 1.444;
}

.txt {
    margin-bottom: 1.222em;
}

header .appStore-googlePlay-lists {
    padding: 40px 0 150px;
}

header .appStore-googlePlay-lists-small li:first-child {
    margin-right: 18px;
}

header .appStore-googlePlay-lists li {
    height: 56px;
    width: 188px;
}

header .appStore-googlePlay-lists-small {
    margin-top: 7px;
}

header .appStore-googlePlay-lists-small li {
    height: 36px;
    width: 124px;
}

header .appStore-googlePlay-lists li:first-child {
    margin-right: 28px;
}

header .appStore-googlePlay-lists .appStore-icon a {
    background-position: left -158px;
}

header .appStore-googlePlay-lists .googlePlay-icon a {
    background-position: -190px -158px;
}

header .appStore-googlePlay-lists-small .appStore-icon a {
    background-position: left -216px;
}

header .appStore-googlePlay-lists-small .googlePlay-icon a {
    background-position: -126px -216px;
}


.header-main .container:before {
    background-image: url(/img/phone-pic.png);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -33px;
    content: "";
    height: 760px;
    position: absolute;
    right: 0;
    width: 620px;
    z-index: 1;
}

main .features-block {
    background-color: #fff;
    margin-top: -230px;
    padding: 60px 0 20px;
    width: 100%;
}

main .password-reset-block {
    max-width: 400px;
    margin: 130px auto 0;
    padding-bottom: 30px;
}

main .password-reset-block .title {
    margin-bottom: 30px;
}

main .form-box {
    margin-bottom: 24px;
}

main .form-box:last-of-type {
    margin-bottom: 30px;
}

main .form-box input {
    background-color: #ededed;
    border-radius: 21px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 42px;
    padding: 0 25px;
    -webkit-transition: border 0.3s ease;
    -moz-transition: border 0.3s ease;
    -ms-transition: border 0.3s ease;
    -o-transition: border 0.3s ease;
    transition: border 0.3s ease;
    width: 100%;
}

main .form-box .error-txt {
    box-sizing: border-box;
    color: #F05454;
    padding: 0 25px;
}

main .form-box input::-webkit-input-placeholder {
 color: #8e8e8e;
}

main .form-box input::-moz-placeholder {
    color: #8e8e8e;
}

main .form-box input:-ms-input-placeholder {
    color: #8e8e8e;
}

main .form-box input:-moz-placeholder {
    color: #8e8e8e;
}

main .form-error .form-box .error-txt {
    display: block;
}

main .form-error .form-box input {
    border-color: #F05454;
}

main .form-box input:focus {
    border-color: #1ec5d1;
}

footer:before,
main .features-block:before,
main .features-block:after {
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
}

main .features-block:before {
    background-image: url("/img/white-top-shape.png");
    background-position: left bottom;
    height: 230px;
    top: -230px;
}

footer:before {
    background-image: url("/img/footer-shape.png");
    background-position: left bottom;
    height: 191px;
    top: -191px;
}

main .features-block:after {
    background-image: url("/img/white-boottom-shape.png");
    background-position: left top;
    height: 191px;
    bottom: -191px;
}

main .features-block .sub-title {
    margin-bottom: 35px;
}

main .trainging-inner {
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;
}

main .trainging-inner {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
}

main .features-list {
    background: url("/img/features-bg.png") center center no-repeat;
    background-size: contain;
}

main .trainging-inner p {
    max-width: 547px;
}

main .training-center-block .title {
    margin-bottom: 30px;
}

main .features-list ul {
    max-width: 470px;
    margin-right: 135px;
    width: 450px;
}

main .features-list ul li {
    box-sizing: border-box;
    margin-bottom: 35px;
    padding-left: 85px;
}

main .features-list h3 {
    margin-bottom: 9px;
}

main .features-list p {
    color: rgba(0,0,0,.7);
}

main .features-list ul > li .counter-txt {
    color: #1ec5d1;
    font-size: 3.889em;
    left: 0;
    position: absolute;
    top: -13px;
}

main .training-center-block {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f49f54+0,ffc152+100 */
    background: #f49f54; /* Old browsers */
    background: -moz-linear-gradient(left, #f49f54 0%, #ffc152 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #f49f54 0%,#ffc152 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #f49f54 0%,#ffc152 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f49f54', endColorstr='#ffc152',GradientType=1 ); /* IE6-9 */
    padding-top: 191px;
}

main .training-center-block .container {
    background: url("/img/training-center-bg.png") no-repeat right center;
    padding-top: 18px;
}

main .btn {
    background-color: #1ec5d1;
    color: #fff;
    text-decoration: none;
}

main .btn:active {
    background-color: #09b5d1 !important;
}

main .training-center-block .learn-more-btn {
    border-radius: 25px;
    height: 50px;
    line-height: 50px;
    min-width: 200px;
    margin-top: 5px;
}

main .password-reset-block .reset-btn {
    border-radius: 21px;
    border: none;
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    min-width: 150px;
}

.static-content {
    box-sizing: border-box;
    padding: 42px 100px 191px;
}

.footer-out {
    padding-bottom: 191px;
}

.static-content h2,
.static-content h3,
.static-content h4{
    margin-bottom: 0.600em;
}

.static-content h5,
.static-content h6 {
    margin-bottom: 1em;
}

.static-content h2 {
    font-size: 2.778em;
}

.static-content h3 {
    font-size: 2.222em;
}

.static-content h4 {
    font-size: 1.667em;
}

.static-content h5 {
    font-size: 1.444em;
}

.static-content h6 {
    font-size: 1.222em;
}

.static-content ol {
    counter-reset: section;
    position: relative;
}

.static-content li {
    padding: 0 0 28px 32px;
    position: relative;
    list-style-type: none;
}

.static-content ul > li:before {
    background-position: -338px -216px;
    content: "";
    height: 12px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 12px;
}

.static-content ol li {
    line-height: 1.222em;
    padding-left: 32px;
}

.static-content ol > li:before {
    counter-increment: section;
    content: counter(section) ". ";
    color: #1ec5d1;
    left: 0;
    position: absolute;
    top: 0;
}

.static-content a {
    color: #1ec5d1;
    text-decoration: none;
}

.static-content iframe {
    max-width: 100%;
}

main .block-out .successful-txt {
    color: rgba(0,0,0,.7);
}

main .block-out {
    padding: 70px 170px 30px;
}

main .block-out .thankyou-block {
    background: url("/img/success-bg.png") center no-repeat;
    background-size: contain;
}

main .block-out .inner-block {
    display: table;
    min-height: 424px;
}

main .block-out .inner {
    padding-left: 150px;
}

main .block-out .inner:before {
    background-position: -391px 0;
    height: 110px;
    left: 0;
    position: absolute;
    top: 14px;
    width: 110px;
}

main .block-out .error-block .inner {
    padding-left: 400px;
}

main .block-out .error-block .inner:before {
    background-position: -5px -257px;
    height: 156px;
    width: 308px;
}

main .block-out .failed-block .inner:before {
    background-position: -391px -263px;
}

main .block-out .title {
    margin-top: -5px;
    margin-bottom: 10px;
}

footer {
    background-color: #f1f1f1;
    box-sizing: border-box;
    padding-bottom: 42px;
}

.wrapper {
    min-height: 100%;
    margin-bottom: -126px;
}

.wrapper:after {
    content: "";
    display: block;
}

.wrapper:after,
footer {
    height: 126px;
}

footer .left-block {
    margin-top: -6px;
}

footer .right-block {
    margin-top: -66px;
}

footer .footer-logo a {
    background-position: left -101px;
}

footer .footer-logo {
    height: 50px;
    margin-bottom: 7px;
    width: 176px;
}

footer .copyright-txt {
    color: #8d8d8d;
}

footer .social-list {
    margin-bottom: 18px;

}

footer .social-list li:first-child {
    margin-right: 20px;
}

footer .social-list a {
    background-color: #bababa;
    border-radius: 50%;
    height: 46px;
    width: 46px;
}

footer .social-list a.fb-icon {
    background-position: -381px -163px;
}

footer .social-list a.twitter-icon {
    background-position: -430px -163px;
}

footer .main-menu li,
footer .social-list li,
footer .about-links li {
    display: inline-block;
}

footer .about-links li {
    margin-right: 45px;
}

footer .about-links li:last-child {
    margin-right: 0;
}

footer .about-links {
    margin-bottom: 23px;
}

footer .about-links li a {
    text-decoration: none;
}

footer .about-links li a,
footer .about-links li span {
    color: #000;
    overflow: hidden;
}

footer .about-links li a.tel-link {
    padding-left: 24px;
}

footer .about-links li a.tel-link:after {
    margin-left: 24px;
}

footer .about-links li a.email-link:after {
    margin-left: 27px;
}

footer .main-menu a:before,
footer .about-links li a:after {
    background-color: #000;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -ms-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

footer .main-menu a:before {
    width: 0;
}

footer .about-links li span:before,
footer .about-links li a:before {
    left: 0;
    position: absolute;
    top: 4px;
}

footer .about-links li a.email-link {
    padding-left: 27px;
}

footer .about-links li .address-link {
    padding-left: 22px;
}

footer .about-links li a.tel-link:before {
    background-position: -482px -133px;
    height: 18px;
    width: 18px;
}

footer .about-links li a.email-link:before {
    background-position: -479px -216px;
    height: 16px;
    top: 5px;
    width: 22px;
}

footer .about-links li .address-link:before {
    background-position: -485px -234px;
    height: 18px;
    width: 16px;
}

footer .main-menu li {
    margin-right: 35px;
}

footer .main-menu li:last-child {
    margin-right: 0;
}

footer .main-menu a {
    color: #000;
    text-decoration: none;
}
.static-content p {
    margin-bottom: 1em;
}